<template>
  <v-row class="pa-5">
    <v-col cols="12" lg="9">
      <v-row>
        <v-col cols="12" sm="9" class="d-flex align-center">
          <v-btn icon class="mr-2" @click="$router.push({ name: 'Dashboard' })">
            <v-icon large>mdi-arrow-left</v-icon>
          </v-btn>
          <span class="font-weight-bold title">{{ title }}</span>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
            v-model="search"
            label="Cari"
            outlined
            dense
            hide-details
            append-icon="mdi-magnify"
          />
        </v-col>
        <v-col cols="12">
          <v-card flat class="pa-4">
            <v-data-table
              :headers="headers"
              :items="items"
              :loading="loading"
              :options.sync="options"
              :server-items-length="total"
              item-key="id"
              class="elevation-0"
            >
              <template #item.action="{ item }">
                <v-btn
                  class="blue mr-2"
                  depressed
                  icon
                  dark
                  small
                  @click="action(item)"
                >
                  <v-icon small>mdi-eye</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" lg="3">
      <side-bar-student />
    </v-col>
  </v-row>
</template>

<script>
import SideBarStudent from "./SideBarStudent.vue";
import { etestHistory, lastEtest, lastElearning } from "@/api/dashboardStudent";

let typingTimer;
const doneTypingInterval = 750;

export default {
  components: { SideBarStudent },
  props: {
    type: String,
    title: String,
  },
  data() {
    return {
      loading: false,
      search: "",
      items: [],
      headers: [],
      query: {
        limit: 10,
        page: 1,
      },
      options: {},
      total: 0,
    };
  },
  created() {
    this.getData();
  },
  watch: {
    options: {
      handler(param) {
        this.query.page = param.page;
        this.query.limit = param.itemsPerPage;

        this.getEtestHistory();
      },
      deep: true,
    },
    search() {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        this.query.page = 1;
        this.getEtestHistory();
      }, doneTypingInterval);
    },
  },
  methods: {
    getData() {
      switch (this.type) {
        case "historyEtest":
          this.headers = [
            { text: "Judul", value: "title" },
            { text: "Mata Pelajaran", value: "subject" },
            { text: "Nilai", value: "score" },
            { text: "Poin", value: "point" },
            { text: "Aksi", value: "action" },
          ];
          this.getEtestHistory();
          break;
        case "lastEtest":
          this.headers = [
            { text: "Judul", value: "title" },
            { text: "Mata Pelajaran", value: "subject" },
            { text: "Aksi", value: "action" },
          ];
          this.getLastEtest();
          break;
        case "lastElearning":
          this.headers = [
            { text: "Judul", value: "title" },
            { text: "Mata Pelajaran", value: "subject" },
            { text: "Aksi", value: "action" },
          ];
          this.getLastElearning();
          break;
      }
    },
    getEtestHistory() {
      this.loading = true;
      etestHistory({ limit: 10, search: this.search })
        .then((res) => {
          if (res.data.code) {
            this.items = res.data.data;
            this.total = res.data.total;
          }
        })
        .finally(() => (this.loading = false));
    },
    getLastEtest() {
      this.loading = true;
      lastEtest({ limit: 10, search: this.search })
        .then((res) => {
          if (res.data.code) {
            this.items = res.data.data;
            this.total = res.data.total;
          }
        })
        .finally(() => (this.loading = false));
    },
    getLastElearning() {
      this.loading = true;
      lastElearning({ limit: 10, search: this.search })
        .then((res) => {
          if (res.data.code) {
            this.items = res.data.data;
            this.total = res.data.total;
          }
        })
        .finally(() => (this.loading = false));
    },
    action(item) {
      switch (this.type) {
        case "historyEtest":
          this.$router.push({
            name: "CorrectionEtest",
            query: {
              etest: item.id,
              person: this.$store.getters.g_user.user.id,
            },
          });
          break;
        case "lastEtest":
          this.$router.push({
            name: "CorrectionEtest",
            query: {
              etest: item.id,
              person: this.$store.getters.g_user.user.id,
            },
          });
          break;
        case "lastElearning":
          this.$router.push({
            name: "ElearningDetail",
            query: {
              id: item.id,
            },
          });
          break;
        default:
          this.$router.push("#");
          break;
      }
    },
  },
};
</script>
