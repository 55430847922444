import request from "@/utils/request";

export function etestHistory(data) {
  return request({
    method: "post",
    url: "dashboard/etest-history-list",
    data: data,
  });
}

export function lastEtest(data) {
  return request({
    method: "post",
    url: "dashboard/last-etest",
    data: data,
  });
}

export function lastElearning(data) {
  return request({
    method: "post",
    url: "dashboard/last-elearning",
    data: data,
  });
}
