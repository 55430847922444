<template>
  <v-card elevation="0">
    <!-- <v-list>
      <span class="ml-4 title font-weight-bold">Menu</span>
      <v-list-item dense class="ma-0" @click="$router.push('#')">
        Materi
      </v-list-item>
      <v-list-item dense class="ma-0" @click="$router.push('#')">
        Petunjuk
      </v-list-item>
      <v-list-item dense class="ma-0" @click="$router.push('#')">
        Nilai Peserta
      </v-list-item>
      <v-list-item dense class="ma-0" @click="$router.push('#')">
        Info
      </v-list-item>
      <v-divider class="mx-3" />
    </v-list> -->
    <v-list>
      <span class="ml-4 title font-weight-bold">E-Test Terbaru</span>
      <v-list-item v-show="loadingLastEtest">
        <v-progress-linear indeterminate color="yellow darken-2" />
      </v-list-item>
      <v-list-item
        v-for="(item, index) in lastEtestData"
        :key="index"
        dense
        class="ma-0"
        @click="
          $router.push({
            name: 'CorrectionEtest',
            query: {
              etest: item.id,
              person: $store.getters.g_user.user.id,
            },
          })
        "
      >
        {{ item.title }}
      </v-list-item>
      <v-list-item
        dense
        class="ma-0"
        @click="$router.push({ name: 'LastEtest' })"
      >
        <span :class="lastEtestData.length > 0 ? 'blue--text' : ''">
          {{ lastEtestData.length > 0 ? "Lihat Semua" : "Data Belum tersedia" }}
        </span>
      </v-list-item>
      <v-divider class="mx-3" />
    </v-list>
    <v-list>
      <span class="ml-4 title font-weight-bold">E-Learning Terbaru</span>
      <v-list-item v-show="loadingLastEtest">
        <v-progress-linear indeterminate color="yellow darken-2" />
      </v-list-item>
      <v-list-item
        v-for="(item, index) in lastElearningData"
        :key="index"
        dense
        class="ma-0"
        @click="
          $router.push({
            name: 'ElearningDetail',
            query: {
              id: item.id,
            },
          })
        "
      >
        {{ item.title }}
      </v-list-item>
      <v-list-item
        dense
        class="ma-0"
        @click="$router.push({ name: 'LastElearning' })"
      >
        <span :class="lastElearningData.length > 0 ? 'blue--text' : ''">
          {{
            lastElearningData.length > 0 ? "Lihat Semua" : "Data Belum tersedia"
          }}
        </span>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { lastEtest, lastElearning } from "@/api/dashboardStudent";

export default {
  created() {
    this.getLastEtest();
    this.getLastElearning();
  },
  data() {
    return {
      loadingLastEtest: false,
      loadingLastElearning: false,
      lastEtestData: [],
      lastElearningData: [],
    };
  },
  methods: {
    getLastEtest() {
      this.loadingLastEtest = true;
      lastEtest({ limit: 5 })
        .then((res) => {
          if (res.data.code) {
            this.lastEtestData = res.data.data;
          }
        })
        .finally(() => (this.loadingLastEtest = false));
    },
    getLastElearning() {
      this.loadingLastElearning = true;
      lastElearning({ limit: 5 })
        .then((res) => {
          if (res.data.code) {
            this.lastElearningData = res.data.data;
          }
        })
        .finally(() => (this.loadingLastElearning = false));
    },
  },
};
</script>
