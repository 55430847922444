var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"elevation":"0"}},[_c('v-list',[_c('span',{staticClass:"ml-4 title font-weight-bold"},[_vm._v("E-Test Terbaru")]),_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingLastEtest),expression:"loadingLastEtest"}]},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"yellow darken-2"}})],1),_vm._l((_vm.lastEtestData),function(item,index){return _c('v-list-item',{key:index,staticClass:"ma-0",attrs:{"dense":""},on:{"click":function($event){return _vm.$router.push({
          name: 'CorrectionEtest',
          query: {
            etest: item.id,
            person: _vm.$store.getters.g_user.user.id,
          },
        })}}},[_vm._v(" "+_vm._s(item.title)+" ")])}),_c('v-list-item',{staticClass:"ma-0",attrs:{"dense":""},on:{"click":function($event){return _vm.$router.push({ name: 'LastEtest' })}}},[_c('span',{class:_vm.lastEtestData.length > 0 ? 'blue--text' : ''},[_vm._v(" "+_vm._s(_vm.lastEtestData.length > 0 ? "Lihat Semua" : "Data Belum tersedia")+" ")])]),_c('v-divider',{staticClass:"mx-3"})],2),_c('v-list',[_c('span',{staticClass:"ml-4 title font-weight-bold"},[_vm._v("E-Learning Terbaru")]),_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingLastEtest),expression:"loadingLastEtest"}]},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"yellow darken-2"}})],1),_vm._l((_vm.lastElearningData),function(item,index){return _c('v-list-item',{key:index,staticClass:"ma-0",attrs:{"dense":""},on:{"click":function($event){return _vm.$router.push({
          name: 'ElearningDetail',
          query: {
            id: item.id,
          },
        })}}},[_vm._v(" "+_vm._s(item.title)+" ")])}),_c('v-list-item',{staticClass:"ma-0",attrs:{"dense":""},on:{"click":function($event){return _vm.$router.push({ name: 'LastElearning' })}}},[_c('span',{class:_vm.lastElearningData.length > 0 ? 'blue--text' : ''},[_vm._v(" "+_vm._s(_vm.lastElearningData.length > 0 ? "Lihat Semua" : "Data Belum tersedia")+" ")])])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }